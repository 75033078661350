










































































































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import appService from "@/services/appService";
import centrifugeClientFactory from "@/services/centrifugeClientFactory";
import merge from "@common/modules/vuetifyx/utils/merge";
import Loading from "@/components/Loading.vue";

export default Vue.extend({
  components: { Loading },
  data() {
    return {
      self: this,
      loading: false,
      items: [],
    };
  },
  methods: {
    async initialize() {
      this.loading = true;
      const { items } = await coreApiClient.call("chargingStations", "findAll", {
        limit: -1,
      });
      this.items = items;
      this.loading = false;
    },
    subscribe() {
      const activeTenant = appService.getActiveTenant();

      const self = this;
      let channelName = "";

      if (activeTenant) {
        channelName = `private-chargingStations-tenantId=${activeTenant._id}`;
      } else if (userManager.checkRole(["cpoAdmin", "cpoMonitor"], true)) {
        channelName = `private-chargingStations-tenantId=${userManager.getUserInfo().tenant._id}`;
      } else if (userManager.checkRole(["retailer"], true)) {
        channelName = `private-chargingStations-tenantId=${userManager.getUserInfo().tenant._id}-retailerAdminUserId=${userManager.getUserInfo()._id
          }`;
      } else if (userManager.checkRole(["user"], true)) {
        channelName = `private-chargingStations-tenantId=${userManager.getUserInfo().tenant._id
          }-connectorItemsActiveUserId=${userManager.getUserInfo()._id}`;
      } else {
        channelName = `private-chargingStations`;
      }
      const socketId = userManager.getUserInfo()._id;

      const centrifuge = centrifugeClientFactory(null, async () => {
        const {
          auth
        } = await coreApiClient.call("chargingStations", "subscribe", {
          socketId,
          channelName,
          id: self.item._id,
          connectorId: 0,
        });
        return auth;
      });
      this.centrifuge = centrifuge;

      if (channelName) {
        const sub = centrifuge.newSubscription(channelName);
        sub.on("publication", (ctx) => {
          const data = (ctx.data);
          self.updateChargingStation(data);
        });

        sub.subscribe();
        centrifuge.connect();
      }
    },
    updateChargingStation(item) {
      const index = this.items.findIndex((it) => it.uniqueId === item.uniqueId);
      if (index >= 0) {
        const previousItem = this.items[index];
        const connectors = previousItem.details?.connectors;
        let newItem: any = merge.clone(previousItem, item);
        if (!newItem.details?.connectors?.length) {
          newItem = merge.clone(newItem, { details: { connectors } });
        }
        this.$set(this.items, index, newItem);
      }
    },
    activeConnectorId(item) {
      if (item.details?.connectors) {
        for (const connector of item.details.connectors) {
          if (connector.status === "Charging") {
            return connector.connectorId;
          }
        }
      }
    },
  },
  created() {
    this.subscribe();
    this.initialize();
  },
  destroyed() {
    if (this.centrifuge) {
      this.centrifuge.disconnect();
    }
  },
});
